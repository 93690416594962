<template>
<!--    <div class="ifmf_sub"><span>{{ memberData.mb_nick }}</span>{{$t('s_c_request')}}</div>-->
    <div class="ifmf_sub">{{$t('s_c_request', {nick : memberData.mb_nick})}}</div>
</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "MemberDetailOnlyNickLayout",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.settingMemberKey();
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        settingMemberKey() {
            if(!util.isEmpty(this.memberData)) {
                EventBus.$emit('setMemberKey', this.memberData.mb_no)
            }
        },
    },
    watch: {
        'memberData': {
            deep: true,
            handler: function (val, oldVal) {
                this.settingMemberKey();
            }
        }
    },
}
</script>

<style scoped>

</style>
